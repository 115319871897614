// For more Icons take a lokk at https://fontawesome.com/

const WLCONFG= {
    SHOW_BLOG:                      false,
    SHOW_AI:                        false,
    USE_CONTENT_IMAGE_ERROR_PAGE:   true,
    USE_CONTENT_IMAGE_THANKS_PAGE:   false,
    SHOW_DASHBOARD_SIDEBAR:         false,
    SHOW_BLOG_SIDEBAR:              true,
    SHOW_BLOG_ARTICLE_SIDEBAR:      true,
    SHOW_ACADEMY_SIDEBAR:           true,
    SHOW_ACADEMY_LESSON_SIDEBAR:    true,
    SHOW_PRODUCT_SIDEBAR:           true,
    SHOW_CHECKLIST_SIDEBAR:         true,
    SHOW_STATISTIC_SIDEBAR:         false,
    SHOW_TRANSCRIBER_SIDEBAR:        true,
    SHOW_AITOOLS_SIDEBAR:           true,
    ACCESSLEVEL:                    '40',
    ICON_NAVIGATION_DASHBOARD:      'gauge-high',
    ICON_NAVIGATION_MY_PRODUCTS:    'cart-arrow-down',
    ICON_NAVIGATION_STATISTICS:     'chart-pie',
    ICON_NAVIGATION_ACADEMY:        'graduation-cap',
    ICON_NAVIGATION_BLOG:           'book',
    ICON_NAVIGATION_AI:             'robot',
    ICON_NEWS:                      'bullhorn',
    ICON_CHARTS:                    'chart-area',
    ICON_OFFER:                     'basket-shopping',
    LOGO_SIGNET:                    'logo_signet.svg',
    LOGO:                           'logo_variant_2.svg',
    ERROR_LOGO:                     'error_logo.svg',
	DASHBORAD_VIDEO:                'Intro-myShopBooster.mp4',
	DASHBORAD_VIDEO_POSTER:         'poster_default.jpg',
    APP_META_TITLE:                 'myShopBooster',
    FAVICON_FOLDER:                 'sb',
    META_SAFE_EQUIV:                'member.myshopbooster.de',
    COMPANY_NAME:                   'ShopBooster E-Commerce GmbH',
    COMPANY_SHORT_NAME:             'MyShopBooster',
    COMPANY_ADDRESS:                'Schmöckwitzer Str. 79a, 15732 Eichwalde, Deutschland',
    COMPANY_EMAIL:                  'service[@]myshopbooster.de',
    COMPANY_PHONE:                  '030 - 609 83 09 63',
    CHAPTER_ADD_CART_ICON_ACTIVE:   'chapter-add-to-cart-icon-active-sb', //css in LessonChapterPayment.vue definieren
    CHAPTER_ADD_CART_ICON_INACTIVE: 'chapter-add-to-cart-icon-inactive-sb', //css in LessonChapterPayment.vue definieren
    NEWSFEED:                       './staticMockData/news_sb.json',
    FACEBOOK_PIXEL_FILE:            './staticMockData/fb_sb.html',
}

export  {
    WLCONFG
}
