<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3><span
            v-if="getProductId() === undefined"
        >Neues </span>Produkt anlegen / ändern</h3>
      </b-col>
    </b-row>

    <b-form id="productEditForm">

      <b-row class="">

        <b-col cols="6">

          <h5 class="mb-3">Produkt-Informationen</h5>

        </b-col>

      </b-row>

      <b-row class="justify-content-md-center">

        <b-col cols="8">

          <b-form-group
              label="Titel:"
              label-for="title"
          >
            <b-form-input
                name="title"
                id="title"
                type="text"
                placeholder="Produkt-Titel"
                :state="validation('title')"
                v-model.trim="formData.title"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('title')">
              Der Produkttitel muss mindestens zwei Zeichen enthalten.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('title')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Produktbeschreibung Hero:"
              label-for="description"
          >
            <b-form-textarea
                id="description"
                name="description"
                v-model.trim="formData.description"
                :state="validation('description')"
                placeholder="Produktbeschreibung als Starttext auf der Landingpage"
                rows="4"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
              label="Produktbeschreibung Slider:"
              label-for="description_short"
          >
            <b-form-textarea
                id="description_short"
                name="description_short"
                v-model.trim="formData.description_short"
                placeholder="Produktbeschreibung als Kurztext im Slider"
                :state="validation('description_short')"
                rows="4"
            ></b-form-textarea>
          </b-form-group>


          <b-form-group
              label="Landingpage / URL:"
              label-for="url"
          >
            <b-form-input
                name="url"
                id="url"
                type="text"
                placeholder="Landingpage / URL"
                :state="validation('url')"
                v-model.trim="formData.url"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('url')">
              Die Landingpage muss mit "https" beginnen.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('url')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Copecart-Checkout URL:"
              label-for="copecart_checkout"
          >
            <b-form-input
                name="copecart_checkout"
                id="copecart_checkout"
                type="text"
                placeholder="Copecart-Checkout URL"
                :state="validation('url')"
                v-model.trim="formData.copecart_checkout"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('url')">
              Die URL muss mit "https" beginnen.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('url')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Produkt-Tag:"
              label-for="tag"
          >
            <b-form-input
                name="tag"
                id="tag"
                type="text"
                placeholder="Produkt-Tag (unique)"
                :state="validation('tag')"
                v-model.trim="formData.tag"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('tag')">
              Der Produkt-Tag darf keine Sonder- und Leerzeichen enthalten und muss einzigartig sein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('tag')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Preis in €:"
              label-for="price"
          >
            <b-input-group append="€">
              <b-form-input
                  name="price"
                  id="price"
                  type="text"
                  placeholder="Produkt-Preis"
                  :state="validation('price')"
                  v-model.trim="formData.price"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('price')">
              Der Preis muss eine Zahl sein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('price')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Streich-Preis in €:"
              label-for="pseudoprice"
          >
            <b-input-group append="€">
              <b-form-input
                  name="pseudoprice"
                  id="pseudoprice"
                  type="text"
                  placeholder="Streich-Preis"
                  :state="validation('pseudoprice')"
                  v-model.trim="formData.pseudoprice"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('pseudoprice')">
              Der Streich-Preis muss eine Zahl sein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('pseudoprice')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Produkt-Typ:"
              label-for="product_type"
          >
            <b-form-select
                name="product_type"
                id="product_type"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="productTypeOptions"
                v-model="formData.product_type"
                :state="validation('product_type')"
            ></b-form-select>
            <b-form-invalid-feedback :state="validation('product_type')">
              Bitte wähle einen Produkt-Typen aus.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('product_type')"></b-form-valid-feedback>
          </b-form-group>

        </b-col>

        <b-col cols="4">

            <span
                v-if="product.product_is_active == 1">

              <b-button
                  type="button"
                  variant="danger"
                  class="mb-4"
                  :disabled="busy"
                  @click="deactivateProduct">Produkt deaktivieren</b-button><br/>
            </span>

          <span
              v-if="product.product_is_active == 0">

              <b-button
                  type="button"
                  variant="danger"
                  class="mb-4"
                  :disabled="busy"
                  @click="activateProduct">Produkt aktivieren</b-button><br/>

            </span>

        </b-col>



      </b-row>

      <b-row
          v-if="getProductId() !== undefined && getProductId() > 0"
      >

        <b-col cols="12">

          <h5 class="mb-3">Promotion-Page</h5>

          <b-form-checkbox
              id="is_promotionpage"
              name="is_promotionpage"
              value="1"
              v-model="formData.is_promotionpage"
          >
            Promotion-Page aktivieren
          </b-form-checkbox>

          <div
            v-if="formData.is_promotionpage == 1"
            class="mt-3"
          >
            <b-form-group
                label="Promotion-Page Titel:"
                label-for="promotion_title"
            >
              <b-form-input
                  name="promotion_title"
                  id="promotion_title"
                  type="text"
                  placeholder="Titel"
                  :state="validation('promotion_title')"
                  v-model.trim="formData.promotion_title"
              ></b-form-input>
              <b-form-invalid-feedback :state="validation('promotion_title')">
                Der Titel muss mindestens 2 Zeichen lang sein.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('promotion_title')"></b-form-valid-feedback>
            </b-form-group>

            <b-form-group
                label="Promotion-Page Beschreibung:"
                label-for="promotion_description"
            >
              <b-form-textarea
                  name="promotion_description"
                  id="promotion_description"
                  v-model.trim="formData.promotion_description"
                  :state="validation('promotion_description')"
                  placeholder="Aufgabenbeschreibung..."
                  rows="6"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="validation('promotion_description')">
                Die Beschreibung muss mindestens 0 Zeichen lang sein.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('promotion_description')"></b-form-valid-feedback>
              <small>Bei Links oder Dokumenten, kannst Du die folgenden Formatierungen einfügen:<br/><i>|primary, |secondary, |button, |label="BUTTON-LABEL"</i><br/>
              Für die Produktbox:<br/><i>{product-box}</i></small>
              <br/>

              <b-button
                  v-if="formData.promotion_description != '' && formData.promotion_description != undefined"
                  variant="primary"
                  class="mt-3 mr-3 button-primary" @click="preview()" type="button">Vorschau</b-button>
            </b-form-group>

          </div>

        </b-col>
      </b-row>

      <b-row
          v-if="getProductId() !== undefined && getProductId() > 0"
      >

        <b-col cols="12">

          <h5 class="mb-3 mt-3">Medien des Produkts</h5>

          <b-form-group
              label="URL / Video:"
              label-for="uvdata"
          >
            <b-input-group>
              <b-form-input
                  name="uvdata"
                  id="uvdata"
                  type="text"
                  placeholder="URL / Video"
                  :state="validation('url')"
                  v-model.trim="uvdata"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                    type="button"
                    variant="primary"
                    @click="sendUrlVideo()"
                ><font-awesome-icon
                    :icon="['fa-solid', 'fa-plus']"
                    class="cursor-pointer"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('url')">
              Die URL muss mit https beginnen.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('url')"></b-form-valid-feedback>
          </b-form-group>

        </b-col>
      </b-row>

      <b-row
          class=""
          v-if="getProductId() !== undefined && getProductId() > 0"
      >

        <b-col cols="12">

          <vue-dropzone
              ref="productDropzone"
              id="dropzoneProduct"
              :options="dropzoneOptions"
              v-on:vdropzone-sending="uploadEvent"
              v-on:vdropzone-success="fileUploaded"
          ></vue-dropzone>

        </b-col>

      </b-row>



      <div
          v-for="(media, media_index) in getMedia"
          :key="media_index"
      >

        <b-row
            v-if="media_index == 0"
            class="media-line mt-5">

          <b-col cols="3">

            <h5>Icon</h5>

          </b-col>

          <b-col cols="5">

            <h5>Dateiname</h5>

          </b-col>

          <b-col cols="3">
            <h5>Medien-Typ
              <b-button
                  type="button"
                  variant="link"
                  class="link-primary text-wrap text-left"
                  @click="loadMediaTypes()"><font-awesome-icon :icon="['fas', 'redo']" /></b-button>
            </h5>

          </b-col>

          <b-col cols="1">
            <h5>Upload</h5>
          </b-col>

        </b-row>

        <b-row class="media-line mt-2">

          <b-col cols="3" class="pt-3 pb-3 text-center">

            <span
                @click="downloadMedia(media)"
                v-if="createTag(media) !== ''"
                v-html="createTag(media)"></span>

            <span
                @click="downloadMedia(media)"
                v-if="media.media_type_description === 'URL'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-link']"
                  class="link-primary media-icon"
              />
            </span>

            <span
                @click="downloadMedia(media)"
                v-if="media.media_type_description === 'Dokument'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-file']"
                  class="link-primary media-icon"
              />
            </span>

          </b-col>

          <b-col cols="5" class="pt-3 pb-3">

            <b-button
                type="button"
                variant="link"
                class="link-primary text-wrap text-left"
                @click="copyText(media.identifier)">{{ media.ressource }}</b-button>

          </b-col>

          <b-col cols="3" class="pt-3 pb-3">
            <strong>{{ media.media_type_description }}</strong><br/>

            <b-form-group
                label="Medien-Typ:"
                :label-for="'product_media_description_type_id[' + media.product_media_id + ']'"
            >
              <b-form-select
                  :name="'product_media_description_type_id[' + media.product_media_id + ']'"
                  :id="'product_media_description_type_id[' + media.product_media_id + ']'"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :options="productMediaDescriptionTypeOptions"
                  v-model="media.product_media_description_type_id"
                  v-on:change="saveProductMediaDescriptionType(media.product_media_id, $event)"
                  :product_media_id="media.product_media_id"
              ></b-form-select>
            </b-form-group>
            <br/>
            <font-awesome-icon
                :icon="['fa-solid', 'fa-file-arrow-down']"
                class="link-primary ml-2"
                @click="downloadMedia(media)"
            />

            <font-awesome-icon
                :icon="['fa-solid', 'fa-trash-can']"
                class="cursor-pointer ml-2 text-danger"
                @click="deleteMedia(media.product_media_id, 0)"
            />

          </b-col>

          <b-col cols="1" class="pt-3 pb-3">
            <span v-if="media.insert_date_formatted === undefined">gerade eben</span>
            <span v-else>{{ media.insert_date_formatted }}</span>
          </b-col>

        </b-row>

      </div>

      <b-row
          v-if="getProductId() !== undefined && getProductId() > 0"
      >

        <b-col cols="12"
          class="checkbox-bottom-space"
        >

          <h5 class="mb-3">Wer darf das Produkt sehen?</h5>

          <b-form-checkbox
              v-for="(user_type, index) in getUserTypes"
              :key="index"
              :id="'product_access_' + index"
              :name="'product_access[' + user_type.user_type_id + ']'"
              :value="user_type.user_type_id"
              v-model="checkedProductAccess"
          >
            {{ user_type.description }} (User-Level: {{ user_type.user_level }})
          </b-form-checkbox>
        </b-col>
      </b-row>


      <b-row
          v-if="getProductId() !== undefined && getProductId() > 0"
      >

        <b-col cols="12">

          <h5 class="mb-3">Upselling</h5>

          <b-form-checkbox
              v-for="(product, index) in upselling"
              :key="index"
              :id="'product_' + index"
              :name="'upselling[' + product.product_id + ']'"
              :value="product.product_id"
              v-model="checkedUpsellingProducts"
          >
            {{ product.product_type }} - {{ product.product_name }} -
            <span
              v-if="product.product_price == 0">kostenlos</span>
            <span
              v-else>{{ Number(product.product_price).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} €</span>
            <span
                v-if="!product.product_is_active" class="text-danger"><strong>inaktiv</strong></span>
          </b-form-checkbox>

        </b-col>
      </b-row>


      <b-row>

        <b-col cols="12">

          <b-button
              v-show="isFormError()"
              type="button"
              variant="primary"
              class="mt-4 mb-4 button-primary"
              @click="saveProduct"
          >Produkt speichern</b-button>

        </b-col>

      </b-row>

      <!-- Checklist -->
      <b-row
          class="justify-content-md-center"
          v-if="formData.product_type == 1 && getProductId() !== undefined"
      >

        <b-col cols="12">

          <h5 class="mb-3 mt-5">Produkt-Konfiguration für eine Checkliste</h5>

          <!-- {{ showSaveChildComponent }} -->
          <product-edit-checklist
              ref="productEditChecklist"
              :product_id="getProductId()"
              @updateShowSaveChildComponent2="updateShowSaveChildComponent"></product-edit-checklist>

        </b-col>

      </b-row>

      <!-- Whitepaper -->
      <b-row
          class="justify-content-md-center"
          v-if="formData.product_type == 2 && getProductId() !== undefined"
      >

        <b-col cols="12">

          <h5 class="mb-3 mt-5">Produkt-Konfiguration für ein Whitepaper</h5>

          <p>Bitte beachte, dass immer nur das <strong>letzte Dokument</strong> genommen wird. Wenn der Dateiname gleich ist, dann wird die Datei überschrieben.</p>
          <p>&nbsp;</p>
          <product-edit-whitepaper
              ref="productEditWhitepaper"
              :product_id="getProductId()"></product-edit-whitepaper>

        </b-col>

      </b-row>


      <!-- Onlinekurs -->
      <b-row
          class="justify-content-md-center"
          v-if="formData.product_type == 3 && getProductId() !== undefined"
      >

        <b-col cols="12">

          <h5 class="mb-3 mt-5">Produkt-Konfiguration für einen Onlinekurs</h5>

          <!-- {{ showSaveChildComponent }} -->
          <product-edit-online-course
              ref="productEditOnlineCourse"
              :product_id="getProductId()"
              @updateShowSaveChildComponent2="updateShowSaveChildComponent"></product-edit-online-course>

        </b-col>

      </b-row>



      <b-button
          v-show="isFormError() && showSaveChildComponent"
          type="button"
          variant="primary"
          class="mt-4 mb-4 button-primary"
          @click="saveProduct"
      >speichern wenn childcomponent</b-button>

      <b-modal size="xl" ref="preview-modal" hide-footer :title="previewModalTitle">
        <div class="d-block text-left">
          <p v-html="previewModalContent"></p>
        </div>
      </b-modal>

      <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
        <template #overlay>
          <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
            <b-icon icon="cloud-upload" font-scale="4"></b-icon>
            <div class="mb-3">Processing...</div>
            <b-progress
                min="1"
                max="20"
                :value="counter"
                variant="success"
                height="3px"
                class="mx-n4 rounded-0"
            ></b-progress>
          </div>
          <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Bist Du Dir sicher?</strong></p>
            <div class="d-flex">
              <b-button variant="danger" class="mr-3" @click="onCancel" type="button">
                Cancel
              </b-button>
              <b-button variant="success" @click="onOK" type="button">OK</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

    </b-form>
  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from "axios";
import productEditChecklist from "@/components/productEditChecklist";
import productEditWhitepaper from "@/components/productEditWhitepaper";
import productEditOnlineCourse from "@/components/productEditOnlineCourse";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
const CONFIG = require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

export default {
  name: "productEdit",
  components: {
    productEditChecklist,
    productEditWhitepaper,
    productEditOnlineCourse,
    vueDropzone: vue2Dropzone,
  },
  props: ['product_id'],
  data() {
    return {

      uvdata: '',

      new_product_id: undefined,
      API_URL_UPLOAD: process.env.VUE_APP_UPLOAD,
      API_URL: process.env.VUE_APP_API,
      headerInfo: CONFIG.WLCONFG.META_SAFE_EQUIV,
      deactivate: false,
      activate: false,
      delete_media_id: 0,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      productTypeOptions: [],
      productMediaDescriptionTypeOptions: [],
      checkedUpsellingProducts: [],
      checkedProductAccess: [],
      newProductData: {
        product_id: '',
        product_name: '',
        product_url: '',
        product_copecart_checkout: '',
        product_price: '',
        product_pseudoprice: '',
        product_description: '',
        product_description_short: '',
        product_type_id: '',
        product_is_active: null,
        product_tag: '',
      },
      formData: {
        title: '',
        url: '',
        copecart_checkout: '',
        price: '',
        pseudoprice: '',
        description: '',
        description_short: '',
        product_type: '',
        tag: '',
        upselling: {},
        product_access: {},
        is_promotionpage: false,
        promotion_title: '',
        promotion_description: '',
      },
      formDataError: {},
      showSaveChildComponent: false,

      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD,
        thumbnailWidth: 150,
        maxFilesize: 1000,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Lade hier Medien für das Produkt hoch.",
        headers: { headerInfo: "productUpload" }
      },

      previewModalTitle: '',
      previewModalContent: '',
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

    this.loadUserTypes();
    this.loadProductList();
    this.loadProductMedia();
    this.loadProductMediaDescriptionTypes();

  },
  created() {

    this.productTypeOptions = this.createProductTypeOptions();
    this.productMediaDescriptionTypeOptions = this.createProductMediaDescriptionTypeOptions();

    this.formData['title'] = this.product.product_name;
    this.formData['url'] = this.product.product_url;
    this.formData['copecart_checkout'] = this.product.product_copecart_checkout;
    this.formData['price'] = this.product.product_price;
    this.formData['pseudoprice'] = this.product.product_pseudoprice;
    this.formData['description'] = this.product.product_description;
    this.formData['description_short'] = this.product.product_description_short;
    this.formData['product_type'] = this.product.product_type_id;
    this.formData['tag'] = this.product.product_tag;
    this.formData['upselling'] = this.product.upselling;
    this.formData['product_access'] = this.product.product_access;
    this.formData['is_promotionpage'] = this.product.product_is_promotionpage;
    this.formData['promotion_title'] = this.product.product_promotion_title;
    this.formData['promotion_description'] = this.product.product_promotion_description;

    for (var index in this.formData['upselling']) {
      this.checkedUpsellingProducts.push(this.formData['upselling'][index].upselling_product_id);
    }

    for (var indexPa in this.formData['product_access']) {
      this.checkedProductAccess.push(this.formData['product_access'][indexPa].user_type_id);
    }


  },
  computed: {
    ...mapGetters([
        'getProductList',
        'getProductTypes',
        'getProductMedia',
        'getProductMediaDescriptionTypes',
        'getUserTypes',
    ]),
    getMedia() {
      return this.getProductMedia;
    },
    product() {

      if (this.getProductId() === undefined) {
        console.log('id nicht gesetzt = neues Product');
        return this.newProductData;
      }

      let productList = this.$store.getters.getProductList;
      var product_id = this.getProductId();

      //console.log('productlist');
      //console.log(productList);
      //console.log('id', this.getProductId());

      let product = {};
      //let product = productList.find(myproduct => myproduct.product_id === Number(this.getProductId()));

      Object.keys(productList).forEach(index => {
        //console.log(index, productList[index]);

        //console.log('suche', productList[index].product_id, product_id);

        if (productList[index].product_id == product_id) {

          //console.log('gefunden', productList[index].product_id);
          //console.log(productList[index]);
          product = productList[index];

        }
      });

      //console.log(product);

      return product;
    },
    upselling() {

      let productList = this.$store.getters.getProductList;

      if (this.getProductId() === undefined) {
        console.log('id nicht gesetzt = neues Product');
        return productList;
      }

      var product_id = this.getProductId();

      let newProductList = {};
      //let product = productList.find(myproduct => myproduct.product_id === Number(this.getProductId()));

      Object.keys(productList).forEach(index => {
        //console.log(index, productList[index]);

        //console.log('suche', productList[index].product_id, product_id);

        if (productList[index].product_id != product_id) {

          //Object.assign(newProductList, productList[index]);
          newProductList[index] = productList[index];

        }
      });

      //console.log(product);

      return newProductList;
    },

  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setProductList',
        'setNewProduct',
        'addToProductList',
        'setProductMediaItem',
        'setProductMedia',
        'setProductMediaDescriptionTypes',
        'setUserTypes',
    ]),
    ...mapGetters([
    ]),
    uploadEvent (file, xhr, formData) {

      //console.log(formData);
      //console.log(xhr);

      console.log('upload',this.getProductId());

      if (this.getProductId() > 0) {
        formData.append('product_id', this.getProductId());
      }
    },
    fileUploaded(file, response) {
      //console.log(file);
      //console.log(response);

      let result = JSON.parse(response);

      console.log(result.product_media_id);

      if (result.product_media_id === undefined) return;

      this.$store.dispatch('setProductMediaItem', result);


    },
    createTag(media) {

      if(media.media_type_description === undefined) return '';

      var str = '';

      switch(media.media_type_description) {

        case 'Bild':
          str += '<img src="' + media.url + '" width="100" border="0"/>';
          break;

        case 'Dokument':
          str += '';
          break;

        case 'Vimeo':
          //str = '<iframe src="http://player.vimeo.com/video/' + media.filename_no_extension + '" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';

          str = '<div style="padding:51.3% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + media.filename_no_extension + '?h=1b6c086b95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Back to Top Link"></iframe></div>';
          break;

        case 'Youtube':
          str = '<iframe width="100%" src="https://www.youtube-nocookie.com/embed/' + media.filename_no_extension + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
          break;

        case 'URL':
          str += "";
          break;

      }

      //console.log(str);

      return str;

    },
    copyText(content) {
      navigator.clipboard.writeText(content);
    },
    getProductId() {
      if (this.product_id === undefined
          && this.new_product_id === undefined
      ) {
        return this.product_id;
      } else if (this.product_id > 0) {
        return this.product_id;
      } else if (this.new_product_id > 0) {
        return this.new_product_id;
      } else {
        return this.product_id;
      }
    },
    async loadProductList() {

      //console.log('loadUserList');

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_productlist',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setProductList', {});

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setProductList', response.data);
        // Set the initial number of items
        this.totalRows = this.getProductList.length;

      }

    },
    async loadProductMedia() {

      if (this.getProductId() === undefined) return;
      if (this.getProductId() === 0) return;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_product_all_data',
            product_id: this.getProductId(),
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setProductMedia', []);

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setProductMedia', response.data.media);

      }

    },
    async saveProductMediaDescriptionType(product_media_id, product_media_description_type_id) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'save_product_media_description_type',
            product_media_id: product_media_id,
            product_media_description_type_id: product_media_description_type_id,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        //console.log('response');
        //console.log(response.data);
        //this.$store.dispatch('setProductMediaDescriptionTypes', response.data);

      }

    },
    async loadProductMediaDescriptionTypes() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_product_media_description_types',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setProductMediaDescriptionTypes', response.data);

      }

    },
    updateShowSaveChildComponent(status) {
      this.showSaveChildComponent = status;
    },
    createProductTypeOptions() {
      let productTypes = this.getProductTypes;
      let options = [];

      //console.log("productTypes");
      //console.log(productTypes);

      Object.keys(productTypes).forEach(index => {

        let obj = {};

        obj['text'] = productTypes[index].product_type;
        obj['value'] = productTypes[index].product_type_id;

        options.push(obj);

      });

      return options;
    },
    async createProductMediaDescriptionTypeOptions() {
      let productTypes = this.getProductMediaDescriptionTypes;
      let options = [
        {
          'text': 'nicht zugewiesen',
          'value': null,
        }
      ];

      //console.log(productTypes);

      Object.keys(productTypes).forEach(index => {

        let obj = {};

        obj['text'] = productTypes[index].product_media_description;
        obj['value'] = productTypes[index].product_media_description_type_id;

        options.push(obj);

      });

      return options;
    },
    validation(type) {

      //console.log(type);

      if (this.formData[type] === undefined) return true;

      var ret = false;
      var data = this.formData[type];

      switch(type) {

        case 'title':

          ret = true;
          if (data.length < 2) {
            ret = false;
          }

          break;

        case 'promotion_title':
        case 'promotion_description':

          ret = true;
          if (data.length < 2 && this.formData.is_promotionpage) {
            ret = false;
          }

          break;

        case 'tag':

          ret = true;
          if (data === null) {
            ret = false;
          } else if (data.length < 2) {
            ret = false;
          }

          break;

        case 'url':

          if (data.startsWith('https://') || data === '') {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'price':
        case 'pseudoprice':

          //var reg = /^[0-9]+$/; /* eslint-disable-line */
          //var reg = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/ /* eslint-disble-line */
          //var reg = /^(\d*([.](?=\d{1}))?\d+)+((?!\2)[.]\d\d)?$/ /* eslint-disble-line */
          var reg = /^\d+(.\d{1,2})?$/ /* eslint-disble-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'product_type':
          //console.log(data);
          if (data === '') {
            //console.log('false');
            ret = false;
          } else {
            ret = true;
          }
          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      //console.log(type, 'ende');
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      //this.$refs.submit.focus()
    },
    onSubmit() {
      this.processing = false
      this.busy = true
    },
    deactivateProduct() {
      this.processing = false;
      this.busy = true;
      this.deactivate = true;
    },
    activateProduct() {
      this.processing = false;
      this.busy = true;
      this.activate = true;

    },
    deleteMedia(media_id) {
      this.processing = false;
      this.busy = true;
      this.delete_media_id = media_id;
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      this.counter = 1;
      this.processing = true;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      if (this.deactivate || this.activate) {

        if (this.deactivate) {

          const response = axios.post(
              this.API_URL,
              {
                type: 'product_deactivate',
                product_id: this.getProductId(),
                cookie: cookie
              }
          );

          console.log(response);

        }

        if (this.activate) {

          const response = axios.post(
              this.API_URL,
              {
                type: 'product_activate',
                product_id: this.getProductId(),
                cookie: cookie
              }
          );

          console.log(response);

        }

        // Simulate an async request
        this.clearInterval()
        this.interval = setInterval(() => {
          if (this.counter < 20) {
            this.counter = this.counter + 1;
          } else {
            this.clearInterval()
            this.$nextTick(() => {
              this.busy = this.processing = false;
            })
          }
        }, 5);

        this.$router.push('/adminProductList');

      } else if (this.delete_media_id != 0) {

        await this.saveProduct();

        const response = await axios.post(
            this.API_URL,
            {
              type: 'delete_media',
              product_id: this.product_id,
              product_media_id: this.delete_media_id,
              cookie: cookie
            }
        );

        // Simulate an async request
        this.clearInterval()
        this.interval = setInterval(() => {
          if (this.counter < 20) {
            this.counter = this.counter + 1;
          } else {
            this.clearInterval()
            this.$nextTick(() => {
              this.busy = this.processing = false;
            })
          }
        }, 5);

        this.loadProductList();
        this.loadProductMedia();

        console.log(response.data);

      }
    },
    async saveProduct() {
      let product = this.product;
      //let product_id = Number(this.product_id);

      let myForm = document.getElementById('productEditForm');
      let formData = new FormData(myForm);

      //console.log(myForm);
      //console.log(formData);

      const data = {};

      const upselling = {};
      var upselling_index = 0;

      for (let [key, val] of formData.entries()) {
        //console.log(data);
        //console.log(key);
        Object.assign(data, {[key]: val})

        if (key.indexOf('upselling') >= 0) {
          //Object.assign(main_categories, {[val]: val});

          upselling[upselling_index++] = { product_id: val};

        }

      }

      const product_access = {};
      var product_access_index = 0;

      for (let [key, val] of formData.entries()) {
        //console.log(data);
        //console.log(key);
        Object.assign(data, {[key]: val})

        if (key.indexOf('product_access') >= 0) {
          //Object.assign(main_categories, {[val]: val});

          product_access[product_access_index++] = { user_type_id: val};

        }

      }

      //console.log('data');
      //console.log(data);

      product.product_name = data.title;
      product.product_url = data.url;
      product.product_copecart_checkout = data.copecart_checkout;
      product.product_description = data.description;
      product.product_description_short = data.description_short;
      product.product_price = data.price;
      product.product_pseudoprice = data.pseudoprice;
      product.product_type_id = data.product_type;
      product.product_tag = data.tag;
      product.upselling = upselling;
      product.product_access = product_access;
      product.product_is_promotionpage = data.is_promotionpage;
      product.product_promotion_title = data.promotion_title;
      product.product_promotion_description = data.promotion_description;

      //console.log(product);

      this.productEdit(product);

    },
    async productEdit(obj) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'product_edit',
            data: obj,
            cookie: cookie
          }
      );

      //console.log(response.data);

      await this.loadProductList();
      await this.loadProductMedia();

      var product_id = Number(response.data);

      if (product_id == 0) return;

      if (this.product_id === undefined) {
        //obj.product_id = product_id;
        //await this.addToProductList(obj);

        this.new_product_id = product_id;

      }

      //await this.addToProductList(obj);

    },
    async sendUrlVideo() {

      if (this.uvdata === '') return;

      //todo: url validierung, nur wenn ok
      //todo: local development
      var cookie = this.$store.getters.getToken;

      var uvdata = this.uvdata;
      this.uvdata = '';

      const response = await axios.post(
          this.API_URL,
          {
            type: 'new_product_url_video',
            product_id: this.getProductId(),
            uvdata: uvdata,
            cookie: cookie
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.$store.dispatch('setChecklistItemMedia', {item: response.data});

      }

    },
    async loadUserTypes() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_user_types',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setUserTypes', response.data);

      }

    },

    downloadMedia(ressource) {

      if ("url" in ressource) {
        //window.location.href = ressource.url;

        let a= document.createElement('a');
        a.target= '_blank';
        a.href= ressource.url;
        a.click();
      }
    },

    async loadMediaTypes() {
      this.productMediaDescriptionTypeOptions = await this.createProductMediaDescriptionTypeOptions();
    },

    async preview() {
      //console.log('preview');

      await this.saveProduct();

      //console.log(item);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_preview_promotion',
            product_id: this.product_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      this.previewModalTitle = response.data.product_promotion_title;
      this.previewModalContent = response.data.product_promotion_description;

      this.$refs['preview-modal'].show();
      //this.$refs['preview-modal'].hide()
    },


  }
}
</script>

<style scoped>

.media-icon {
  font-size: 30pt;
}

.text-wrap {
  overflow-wrap: anywhere;
}

.checkbox-bottom-space {
  margin-bottom: 20px;
}

</style>