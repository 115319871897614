/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
  var newYear = new Date(this.getFullYear(),0,1);
  var day = newYear.getDay() - dowOffset; //the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  var daynum = Math.floor((this.getTime() - newYear.getTime() -
      (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
  var weeknum;
  //if the year starts before the middle of a week
  if(day < 4) {
    weeknum = Math.floor((daynum+day-1)/7) + 1;
    if(weeknum > 52) {
      var nYear = new Date(this.getFullYear() + 1,0,1);
      var nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  }
  else {
    weeknum = Math.floor((daynum+day-1)/7);
  }
  return weeknum;
};

/**
 * --------------------------------------------------------------------------
 * credits 
 * @param : message1 : string
 * @param : message2 : string
 * --------------------------------------------------------------------------
 */
 const VERSION = '1.0.0';
 const credits  = (message1, message2) => {
	console.log(
		"%c" + message1 + "%c" + message2,
		"color: white;  border: 1px solid #AD0267; text-transform:uppercase;background: #AD0267; font-size: 11px; padding: 3px 8px;line-height:2em;",
		"color: white;background: #52388E;border: 1px solid #52388E; font-size: 11px; padding: 3px 12px;line-height:2em;border-left:0;"
	);
};


import Vue from 'vue'
import App from './App.vue'
import store from './store'
//import { router } from "@/routes";
//muss durch beforeEach in routes.js so gesetzt werden
import router from "@/routes";
import VueMeta from 'vue-meta';


import VueCookies from 'vue-cookies';
Vue.use(VueCookies);


Vue.use(VueMeta);

import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles.scss';

//todo: Design Editor wirft einen Fehler
//import 'vue-color-gradient-picker/dist/index.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//fas
/*
import { faPlay, faStop, faMicrophone, faPowerOff, faVolumeOff, faVolumeUp, faStopCircle, faPlayCircle, faWifi, faTimes, faCloudUploadAlt, faSync, faKey, faSignOutAlt, faUser, faUserGear, faUsersGear, faGears, faChartLine, faUserGraduate, faChild, faCrown, faBaby, faUserPlus, faXmark, faCheck, faUserPen } from '@fortawesome/free-solid-svg-icons'
//far
//import { faSmile, faMeh, faPauseCircle } from '@fortawesome/free-regular-svg-icons'
//fab
//import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faPlay, faStop, faMicrophone, faPowerOff, faVolumeOff, faVolumeUp, faStopCircle, faPlayCircle, faWifi, faTimes, faCloudUploadAlt, faSync, faKey, faSignOutAlt, faUser, faUserGear, faUsersGear, faGears, faUserGraduate, faChartLine, faChild, faCrown, faBaby, faUserPlus, faXmark, faCheck, faUserPen)

 */

import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(BootstrapVue, {
  breakpoints: [`xs`, 'sm', 'md', 'lg', 'xl', 'cs', 'xxl']
})
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)

Vue.filter('striptags', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace(/<\/?[^>]+(>|$)/g, "")
});

Vue.filter('truncate', function (value, num = 100) {
  if (!value) return ''
  value = value.toString()
  return value.split("").slice(0, num).join("") + '...'
});

Vue.filter('tostring', function (value) {
  if (!value) return ''
  return value.toString()
});


/** HELPERS - Utillities*/
import * as Utils from '@/utils';

const utils = {
	install (Vue) {
    Vue.utils = Utils;
		Vue.prototype.$utils = Utils;
	}
};


Vue.use(utils);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexChart', VueApexCharts)


/*
Translations
*/
import Polyglot from 'vue-polyglot';
Vue.use(Polyglot, {
  defaultLanguage: 'de',
  languagesAvailable: ['de']
});

import * as LOCALE from './languages/index';
Vue.locales(LOCALE.languages)

Vue.config.productionTip = false
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)
new Vue({
  store,
  BootstrapVue,
  IconsPlugin,
  router,
  metaInfo: {
    title: CONFIG.WLCONFG.APP_META_TITLE,
    titleTemplate: '%s | Member Area',
    meta: [      
      { 'http-equiv': 'Content-Security-Policy',  content:`default-src 'self' https://connect.facebook.net https://www.facebook.com data: gap: ws: ${CONFIG.WLCONFG.META_SAFE_EQUIV} 'unsafe-inline'; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval' ${CONFIG.WLCONFG.META_SAFE_EQUIV} player.vimeo.com; script-src-elem 'self' 'unsafe-inline' 'unsafe-eval' ${CONFIG.WLCONFG.META_SAFE_EQUIV} https://connect.facebook.net https://www.facebook.com; frame-src http://player.vimeo.com https://www.youtube-nocookie.com https://www.youtube.de https://www.youtube.com https://www.youtu.be https://${CONFIG.WLCONFG.META_SAFE_EQUIV};`},
      { name: 'msapplication-TileColor', content: '#ffffff' },
      { name: 'msapplication-TileImage', content: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/ms-icon-144x144.png` },
      { name: 'theme-color', content: '#ffffff' },
    ],
    link: [
      {rel: 'apple-touch-icon', sizes: '57x57', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-57x57.png`},
      {rel: 'apple-touch-icon', sizes: '72x72', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-72x72.png`},
      {rel: 'apple-touch-icon', sizes: '76x76', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-76x76.png`},
      {rel: 'apple-touch-icon', sizes: '114x114', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-114x114.png`},
      {rel: 'apple-touch-icon', sizes: '120x120', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-120x120.png`},
      {rel: 'apple-touch-icon', sizes: '144x144', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-144x144.png`},
      {rel: 'apple-touch-icon', sizes: '180x180', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-180x180.png`},
      //{rel: 'apple-touch-icon', sizes: '192x192', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-192x192.png`},
      {rel: 'apple-touch-icon', sizes: '32x32', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-32x32.png`},
      {rel: 'apple-touch-icon', sizes: '96x96', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-96x96.png`},
      {rel: 'apple-touch-icon', sizes: '16x16', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/apple-icon-16x16.png`},
      {rel: 'icon', type: 'image/png', sizes: '192x192', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/android-icon-192x192.png`},
      {rel: 'icon', type: 'image/png', sizes: '32x32', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/favicon-32x32.png`},
      {rel: 'icon', type: 'image/png', sizes: '96x96', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/favicon-96x96.png`},
      {rel: 'icon', type: 'image/png', sizes: '16x16', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/favicon-16x16.png`},
      {rel: 'manifest', sizes: '16x16', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/manifest.json`},
      {rel: 'shortcut icon', type: 'image/png', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/favicon.png`},
      {rel: 'icon', type: 'image/x-icon', href: `/favicon/${CONFIG.WLCONFG.FAVICON_FOLDER}/favicon.ico`}
    ],
  },
  render: h => h(App),
}).$mount('#app')

credits(
  '© Shopentwickler: Version ' + VERSION,
  'Gerrick Kammholz / Alexander Tschakarov ' + new Date().getFullYear()
)
